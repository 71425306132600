import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage2',
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HomePage2')
  },
  {
    path: '/career',
    name: 'CareerPage',
    // route level code-splitting
    // this generates a separate chunk (career.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CareerPage')
  },
  {
    path: '/affiliate',
    name: 'AffiliatePage',
    // route level code-splitting
    // this generates a separate chunk (affiliate.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AffiliatePage')
  },
  {
    path: '/about',
    name: 'AboutPageSecondary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPageSecondary')
  },
  {
    path: '/ambassador',
    name: 'AmbassadorPage',
    // route level code-splitting
    // this generates a separate chunk (ambassador.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AmbassadorPage')
  },
  {
    path: '/blog',
    name: 'BlogPage',
    // route level code-splitting
    // this generates a separate chunk (blog.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogPage')
  },
  {
    path: '/news-details/:slug',
    name: 'BlogSingle',
    // route level code-splitting
    // this generates a separate chunk (single.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogSingle')
  },
  {
    path: '/pricing',
    name: 'PricingPage',
    // route level code-splitting
    // this generates a separate chunk (pricing.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PricingPage')
  },
  {
    path: '/product',
    name: 'ProductPage',
    // route level code-splitting
    // this generates a separate chunk (product.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductPage')
  },
  {
    path: '/faq',
    name: 'FaqPage',
    // route level code-splitting
    // this generates a separate chunk (faq.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqPage')
  },
  {
    path: '/atm',
    name: 'AtmPage',
    // route level code-splitting
    // this generates a separate chunk (atm.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AtmPage')
  },
  {
    path: '/card',
    name: 'CardPage',
    // route level code-splitting
    // this generates a separate chunk (card.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CardPage')
  },
  {
    path: '/contact',
    name: 'ContactPage',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactPage')
  },
  {
    path: '/staking',
    name: 'StackingPage',
    // route level code-splitting
    // this generates a separate chunk (stack.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/StackingPage')
  },
  {
    path: '/terms',
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (stack.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Terms')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (stack.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy')
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    // route level code-splitting
    // this generates a separate chunk (stack.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Disclaimer')
  },
  {
    path: '/franchising',
    name: 'Franchising',
    // route level code-splitting
    // this generates a separate chunk (stack.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FranchisePage')
  },
  {
    path: '/currencies',
    name: 'Currencies',
    // route level code-splitting
    // this generates a separate chunk (stack.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CurrencyPage')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
  routes
})

export default router
